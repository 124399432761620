body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
	scroll-behavior: smooth !important;
}

html {
	scroll-behavior: smooth !important;
}

button.MuiToggleButton-root.Mui-selected {
	background-color: #a52626 !important;
	color: white;
}
button.MuiToggleButton-root:not(.Mui-selected) {
	background-color: white !important;
	border-color: #000 !important;
	color: black;
}

.noScrollBarListItem::-webkit-scrollbar {
	width: 20px !important; /* Remove scrollbar space */
	background: transparent !important; /* Optional: just make scrollbar invisible */
	padding-left: 10px !important;
}
.noScrollBarListItem::-webkit-scrollbar-thumb {
	background-color: #999999;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	background-clip: content-box;
}
.Mui-selected {
	background-color: rgba(255, 215, 0, 0.8) !important;
}

#Sponzori a:hover {
	transform: scale(1.15);
}

#Sponzori a{
	transition: transform .5s;
	height: 160px;
	display: flex !important;
}

#Sponzori img{
	margin: auto;
}

.slick-arrow::before{
	color: #000 !important;
}

.scale3:hover{
	transform: scale(1.03);
}

.scale3{
	transition: transform .4s !important;
}

.error{
	color:rgb(129, 0, 0);
}

.galleryImage{
	object-fit: cover;
	height: 250px;
	width: 100%;
}

.adminGalleryImage{
	object-fit: cover;
	height: 250px;
	width: 100%;
}

.dressInfoImage{
	object-fit: cover;
	width: 100%;
}

.MuiTab-textColorPrimary{
	color: rgb(31, 31, 31) !important
}

.newsCover{
	height: 300px;
	width: 100%;
	object-fit: contain;
	display: flex;
	margin-left: auto;
	margin-right: auto;
}

.public-DraftStyleDefault-ltr{
	text-align: inherit !important;
}

.newsArticleWrapper-class {
	border: 1px solid #ccc;
	padding: 0px;
	height: 150px !important;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
	border-radius: 5px;
}
.editor-class {
  background-color: #f9f7f6;
  padding: 1rem;
  border: 1px solid #ccc;
	border-radius: 5px;
}
.toolbar-class {
  border: 1px solid #ccc;
	border-radius: 5px;
}

.hideOnSmall {
	display: block !important;
}

.hideOnLarge {
	display: none !important;
}

.invalid-feedback{
	color:rgb(129, 0, 0);
}

@media screen and (max-width: 600px) {
	.hideOnSmall {
		display: none !important;
	}
	.hideOnLarge {
		display: block !important;
	}
  .spreadWidthOnSmall{
    width: 100% !important;
  }
  .hideBG{
    background-image: none !important;
  }
  .revertBGColor{
    background-color:rgba(0, 0, 0, 0) !important;
    color: #000 !important;
  }
  .lowerYPadding{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 899px) {
	.hideOnSmall {
		display: none !important;
	}
	.hideOnLarge {
		display: block !important;
	}
  .spreadWidthOnSmall{
    width: 100% !important;
  }
}
